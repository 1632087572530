import React from 'react';
import Payment1Img from 'src/images/choatobarai/payment-in-3-months/payment-in-3-months-1.svg';
import Payment2Img from 'src/images/choatobarai/payment-in-3-months/payment-in-3-months-2.svg';
import DoubleArrowImg from 'src/images/choatobarai/double-arrow.svg';
import styles from './ChoatobaraiPaymentIn3Months.module.scss';

const STEPS = [
  {
    img: Payment1Img,
    texts: (
      <>
        <p className={styles.headline}>
          <span className={styles.pink}>1.</span> 商品を購入
        </p>
        <p className={styles.content}>
          「超あと払い」を選択してお買いものをします。
          購入の流れは通常のペイディと同じです。
        </p>
      </>
    ),
  },
  {
    img: Payment2Img,
    texts: (
      <>
        <p className={styles.headline}>
          <span className={styles.pink}>2.</span> 10日までにお支払い
        </p>
        <p className={styles.content}>
          請求月の10日までにコンビニまたは銀行振込でお支払いください。
          <br />
          <br />
          (ご利用例：4月10日までにコンビニまたは銀行振込にてお支払いください。
          <br />
          なお、通常のペイディのご利用がある場合、そのご利用分は超あと払いご利用分と一緒に請求されます。）
        </p>

        <p className={styles.note}>
          *口座振替ご登録の方は12日に引き落としされます。
        </p>
      </>
    ),
  },
];

export default function ChoatobaraiPaymentIn3Months() {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>お支払いは3ヶ月後！</h1>
      <p className={styles.description}>
        超あと払いの1ヶ月間のご利用分をご利用月の3ヶ月後にまとめてお支払いいただけます。
      </p>
      <p className={styles.stepsHead}>ご利用例</p>
      <div className={styles.steps}>
        {STEPS.map((step, index) => (
          <>
            <div className={styles.step} key={`step-${index}`}>
              <img
                src={step.img}
                alt={`step-${index}`}
                width={100}
                height={100}
              />
              <div className={styles.texts}>{step.texts}</div>
            </div>
            {index < STEPS.length - 1 && (
              <img
                className={styles.doubleArrow}
                src={DoubleArrowImg}
                width={22}
                height={36}
                alt="double-arrow"
              />
            )}
          </>
        ))}
      </div>
    </section>
  );
}
