import React from 'react';
import logoShopList from 'src/images/choatobarai/logo-shop-list.svg';
import logoPaidyPink from 'src/images/choatobarai/logo-paidy-pink.svg';
import iconCross from 'src/images/choatobarai/icon-cross.svg';
import styles from './ChoatobaraiBanner.module.scss';

export default function ChoatobaraiBanner() {
  return (
    <section className={styles.section}>
      <h1 className={styles.headline}>
        お支払いは
        <br />
        3ヶ月後でOK
      </h1>
      <div className={styles.logoContainer}>
        <img src={logoShopList} alt="lgoo-shop-list" width={180} height={46} />
        <img
          src={iconCross}
          alt="icon-cross"
          width={31}
          height={31}
          className={styles.iconCross}
        />
        <img
          src={logoPaidyPink}
          alt="logo-paidy-pink"
          width={238}
          height={49}
        />
      </div>
      <h2 className={styles.headline2}>「超あと払い」とは</h2>
      <p className={styles.description}>
        「超あと払い」とは、SHOPLIST.com by
        CROOZとペイディが共同で提供している決済手段です。
        超あと払いの1ヶ月間のご利用分をご利用月の3ヶ月後にまとめてお支払いいただけます。
      </p>
    </section>
  );
}
