import React from 'react';
import styles from './StepsWithHeadline.module.scss';

export default function StepsWithHeadline(props) {
  const { title, steps } = props;

  return (
    <section className={styles.section}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.steps}>
        {steps.map((step, index) => (
          <div className={styles.step} key={`step-${index}`}>
            <p className={styles.index}>STEP {index + 1}</p>
            <p className={styles.textHead}>{step.title}</p>
            <img
              className={styles.img}
              src={step.img}
              alt={`step-${index}`}
              width={180}
              height={390}
            />
            <p className={styles.description}>{step.description}</p>
          </div>
        ))}
      </div>
      {/* <div className={styles.brownBg} /> */}
    </section>
  );
}
