import React from 'react';
import cx from 'classnames';
import PaymentFlow1Img from 'src/images/choatobarai/payment-flow/payment-flow-1.svg';
import PaymentFlow2Img from 'src/images/choatobarai/payment-flow/payment-flow-2.svg';
import PaymentFlow3Img from 'src/images/choatobarai/payment-flow/payment-flow-3.svg';
import PaymentFlow4Img from 'src/images/choatobarai/payment-flow/payment-flow-4.svg';
import styles from './ChoatobaraiStepsPaymentFlow.module.scss';
import Steps from '../Apple/Steps';

const STEPS = [
  {
    image: PaymentFlow1Img,
    info: (
      <>
        <b className={styles.stepHeadline}>商品を購入</b>
        <p className={styles.stepDescription}>
          購入時に「超あと払い」を支払い方法としてお選びください。
        </p>
      </>
    ),
  },
  {
    image: PaymentFlow2Img,
    info: (
      <>
        <b className={styles.stepHeadline}>商品のお届け</b>
        <p className={cx(styles.stepDescription, styles.alignCenter)}>
          商品が先に届きます。
        </p>
      </>
    ),
  },
  {
    image: PaymentFlow3Img,
    info: (
      <>
        <b className={styles.stepHeadline}>
          3ヶ月後に請求案内を
          <br />
          メールでお届け
        </b>
        <p className={cx(styles.stepDescription, styles.alignCenterMobile)}>
          ご利用日から3ヶ月後の1日〜3日の間に請求案内がメール・SMSで届きます。
        </p>
      </>
    ),
  },
  {
    image: PaymentFlow4Img,
    info: (
      <>
        <b className={styles.stepHeadline}>10日までにお支払い</b>

        <p className={styles.stepDescription}>
          請求月の10日までにコンビニまたは銀行振込でお支払いください。
        </p>
        <br />
        <p className={styles.stepNote}>
          *口座振替ご登録の方は12日に引き落としされます。
        </p>
      </>
    ),
  },
];

export default function ChoatobaraiStepsPaymentFlow() {
  return (
    <section className={styles.section}>
      <Steps
        steps={STEPS}
        title="お支払いの流れ"
        imageWidth={184}
        gap={38}
        columnWidth={264}
        textAlign="center"
      />
    </section>
  );
}
