import React from 'react';
import Questions from '../Apple/Questions';
import styles from './ChoatobaraiQA.module.scss';

const QUESTIONS = [
  {
    question: '超あと払いとは何ですか？',
    answer: (
      <>
        「超あと払い」はペイディ決済の新しい機能です。通常のペイディとの大きな違いは、
        ご利用金額のお支払い期限がより長くなる(ご利用月の３ヶ月後）ことです。
        超あと払いは現在、
        <br />
        SHOPLIST.comでのお買い物にご利用いただけます。
      </>
    ),
  },
  {
    question: '支払い期限はいつですか。',
    answer: (
      <>
        超あと払いの1ヶ月間のご利用分をご利用月の3ヶ月後にまとめてお支払いいただけます
        <br />
        事例：
        <br />
        1月13日: 「超あと払い」でお買い物
        <br />
        ↓
        <br />
        4月1日：1月の超あと払いご利用分と3月の通常のペイディご利用分の請求が確定し、請求案内メール・SMSが届きます。ご案内に従って、4月10日までにコンビニあるいは銀行振込でお支払いください。なお、口座振替ご登録の方が請求金額が4月12日に引き落とされます。
      </>
    ),
  },
  {
    question: '追加情報（氏名、生年月日、住所）の入力はなぜ必要ですか。',
    answer: (
      <>
        超あと払いのお支払い期限がより長く、与信審査方法は通常のペイディと違います。
        <br />
        超あと払いの決済画面に入力いただく追加情報は決済の与信審査に必要となります。
      </>
    ),
  },
  {
    question: '超あと払いは手数料かかりますか。',
    answer:
      '超あと払いのご利用分が含まれている請求金額をコンビニでお支払いされた場合、コンビニ支払い手数料は発生しません。',
  },
];

export default function ChoatobaraiQA() {
  return (
    <Questions
      questions={QUESTIONS}
      title="よくあるご質問"
      wrapperClassname={styles.wrapper}
      isLightBrown
    />
  );
}
