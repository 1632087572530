import React from 'react';
import PaidyLogo from 'src/images/choatobarai/paidy-logo-with-text.svg';
import DownloadAppleStore from 'src/images/choatobarai/download-apple-store.svg';
import DownloadGooglePlay from 'src/images/choatobarai/download-google-play.svg';
import styles from './ChoatobaraiAppDownload.module.scss';
import { trackAppDownload } from '../../utils';

export default function ChoatobaraiAppDownload() {
  return (
    <section className={styles.section}>
      <img src={PaidyLogo} alt="paidy-logo-with-text" width={142} height={56} />
      <p className={styles.text}>
        ペイディアプリでお買い物を
        <br />
        もっと便利に。もっと楽しく。
      </p>
      <div className={styles.downloadContainer}>
        <a
          className={styles.downloadApple}
          href="https://apps.apple.com/jp/app/paidy/id1220373112"
          target="_blank"
          rel="noreferrer"
          onClick={() => trackAppDownload('App Store', 'choatobarai')}
        >
          <img
            src={DownloadAppleStore}
            alt="paidy-logo-with-text"
            width={140}
            height={42}
          />
        </a>
        <a
          className={styles.downloadGoogle}
          href="https://play.google.com/store/apps/details?id=com.paidy.paidy"
          target="_blank"
          rel="noreferrer"
          onClick={() => trackAppDownload('Google Play', 'choatobarai')}
        >
          <img
            src={DownloadGooglePlay}
            alt="paidy-logo-with-text"
            width={140}
            height={42}
          />
        </a>
      </div>
    </section>
  );
}
