import React from 'react';
import Step1Img from 'src/images/choatobarai/steps-how-to-use/paidy-the-same-way-1.png';
import Step2Img from 'src/images/choatobarai/steps-how-to-use/paidy-the-same-way-2.png';
import Step3Img from 'src/images/choatobarai/steps-how-to-use/paidy-the-same-way-3.png';
import Step4Img from 'src/images/choatobarai/steps-how-to-use/paidy-the-same-way-4.png';
import Step5Img from 'src/images/choatobarai/steps-how-to-use/paidy-the-same-way-5.png';
import StepsWithHeadline from './StepsWithHeadline';

const TITLE = '使い方は通常のペイディとほぼ同じ';
const STEPS = [
  {
    title: '「超あと払い」を選択',
    img: Step1Img,
  },
  {
    title: (
      <>
        メールアドレスと
        <br />
        携帯電話番号を入力
      </>
    ),
    img: Step2Img,
  },
  {
    title: 'PINコードを入力',
    img: Step3Img,
  },
  {
    title: '必要な情報を入力',
    img: Step4Img,
    description: '*お買い物する商品により、入力項目が変わる場合がございます。',
  },
  {
    title: '決済完了！',
    img: Step5Img,
  },
];

export default function ChoatobaraiStepsHowToUse() {
  return <StepsWithHeadline title={TITLE} steps={STEPS} />;
}
