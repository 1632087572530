import React from 'react';
import LazyLoad from 'react-lazyload';
import useHorizontalScrollNavigation from '../../hooks/useHorizontalScrollNavigation';
import styles from './CanHorizontalScrollNavigation.module.scss';

const ScrollItem = ({ ImgComponent, figCaption }) => {
  return (
    <figure className={styles.howToRowItem}>
      <div className={styles.howToRowItemFigure}>
        <LazyLoad height={184} offset={1000} once>
          <ImgComponent />
        </LazyLoad>
      </div>
      <figcaption className={styles.featureRowItemCaption}>
        {figCaption}
      </figcaption>
    </figure>
  );
};

const HorizontalScrollNavigation = ({ items }) => {
  const {
    ref,
    navigationLength,
    focusedIndex,
    isOverflow,
  } = useHorizontalScrollNavigation({
    contentClassName: styles.howToRowItem,
  });

  return (
    <div className={styles.bgContainer}>
      <div
        className={styles.container}
        style={{
          width: isOverflow ? '100%' : undefined,
        }}
      >
        <div className={styles.horizontalScrollContainer}>
          <div className={styles.horizontalScrollInner} ref={ref}>
            {items.map((item) => (
              <React.Fragment>
                <ScrollItem ImgComponent={item.image} figCaption={item.text} />
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className={styles.navigationContainer}>
          {[...new Array(navigationLength).fill(null)].map((_, index) => (
            <div
              className={styles.navigationDot}
              style={{
                opacity: index === focusedIndex ? 1 : 0.3,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HorizontalScrollNavigation;
