/* eslint-disable react/no-danger */
// @flow
import React from 'react';
import cx from 'classnames';
import HorizontalScrollNavigation from './CanHorizontalScrollNavigation';
import styles from '../../styles/pages/landing.module.scss';
import canStyles from './Can.module.scss';

const data = {
  topTitlePc: `ペイディアプリで、できること。`,
  topTitleSp: (
    <>
      ペイディアプリで、
      <br />
      できること。
    </>
  ),
};

const items = [
  {
    image: () => (
      <img
        src={require('../../images/landing/can_1.png')}
        alt={'毎月のご請求のお支払いもアプリを見せるだけ。'}
      />
    ),
    text: `毎月のご請求のお支払いもアプリを見せるだけ。`,
  },
  {
    image: () => (
      <img
        src={require('../../images/landing/can_2.png')}
        alt={
          '「お買い物＆発見」で欲しいものを見つけて、ペイディでスムーズに購入。'
        }
      />
    ),
    text:
      '「お買い物＆発見」で欲しいものを見つけて、ペイディでスムーズに購入。',
  },
  {
    image: () => (
      <img
        src={require('../../images/landing/can_3.png')}
        alt={'見やすい利用履歴でお金の流れがひと目でわかる。'}
      />
    ),
    text: '見やすい利用履歴でお金の流れがひと目でわかる。',
  },
];

const Can = () => {
  return (
    <section className={cx(canStyles.section, styles.can)}>
      <div className={styles.inner}>
        <h2 className={cx(styles.ttl, canStyles.pc)}>{data.topTitlePc}</h2>
        <h2 className={cx(styles.ttl, canStyles.sp)}>{data.topTitleSp}</h2>
        <HorizontalScrollNavigation items={items} />
      </div>
    </section>
  );
};

export default Can;
