import React from 'react';
import styles from './ChoatobaraiPreventLatePayment.module.scss';

export default function ChoatobaraiPreventLatePayment() {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <p className={styles.title}>超あと払いに関する注意点</p>
        <p className={styles.content}>
          未成年のお客様は必ず保護者の同意を得た上でご利用ください。なお、18歳以下のお客様はご利用いただけません。
          <br />
          <br />
          超あと払い申込に際し、申込者の情報を信用情報機関に照会・利用・登録します。
          契約が成立した場合、申込者の属性・契約内容・支払状況（滞納情報を含む）を信用情報機関に登録します。
          照会情報は照会した日から半年間、支払遅延情報は完済後５年間は信用情報機関に記録が残り、
          信用情報機関の加盟会員が審査のため利用できます。将来の他のクレジットご利用に影響を与える可能性がございます。
          詳細は個人情報の取扱いに関する同意条項をご覧ください。
          <br />
          <br />
          当社の判断でご注文完了後にキャンセルさせていただく場合がございますので予めご了承ください。
        </p>
      </div>
    </section>
  );
}
