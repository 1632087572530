import React from 'react';
import { SITE_METADATA } from 'src/constants';
import Layout from 'src/components/Layout/Layout';
import ChoatobaraiBanner from 'src/components/Choatobarai/ChoatobaraiBanner';
import ChoatobaraiStepsHowToUse from 'src/components/Choatobarai/ChoatobaraiStepsHowToUse';
import ChoatobaraiPaymentIn3Months from 'src/components/Choatobarai/ChoatobaraiPaymentIn3Months';
import ChoatobaraiStepsPaymentFlow from 'src/components/Choatobarai/ChoatobaraiStepsPaymentFlow';
import Can from 'src/components/Landing/Can';
import ChoatobaraiAppDownload from 'src/components/Choatobarai/ChoatobaraiAppDownload';
import ChoatobaraiPreventLatePayment from 'src/components/Choatobarai/ChoatobaraiPreventLatePayment';
import ChoatobaraiQA from 'src/components/Choatobarai/ChoatobaraiQA';

const Plus3Pay = () => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.plus3Pay.title,
        description: SITE_METADATA.plus3Pay.description,
      }}
    >
      <ChoatobaraiBanner />
      <ChoatobaraiStepsHowToUse />
      <ChoatobaraiPaymentIn3Months />
      <ChoatobaraiStepsPaymentFlow />
      <Can />
      <ChoatobaraiAppDownload />
      <ChoatobaraiPreventLatePayment />
      <ChoatobaraiQA />
    </Layout>
  );
};

export default Plus3Pay;
